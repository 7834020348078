.modal-content {
  background-color: #343845 !important;
  .modal-header {
    border-bottom: 1px solid #ef7b1b63;
    .modal-title{
      font-size: 22px; font-weight: 500;
    }
  }
  .modal-body {
    background-color: #343845;
    .pop_body {
      display: flex;
      justify-content: space-between;
      background-color: #202231;
      padding: 20px;
      border-radius: 17px;
      margin: 15px 0;
      p.text-muted {
        margin: 0;
        font-size: 20px;
      }
    }
    .form-group{
      input.form-control {
        background: #ffffff14 0% 0% no-repeat padding-box;
        border: 1px solid #5b6172;
        border-radius: 6px;
        opacity: 1;
        height: 48px;
        color: #fff;
        padding: 0 15px;
      }
    }
    .btn {
      font-size: 18px;
      height: 50px;
      border-radius: 50px;
      line-height: 39px;
      padding: 6px 35px;
    }
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: 9;
  }
}
.request-modal{
  padding: 20px;
}
.text-right{
  text-align: right;
}