@import "../../Theme/_vars.scss";
@import "../../Theme/_mixins.scss";
.btn {
  background: $buttonbg;
  box-shadow: 0px 0px 30px #ef7f1b57;
  border: none;
  font-size: 20px;
  color: $white;
  font-weight: 300;
  height: 58px;
  border-radius: 50px;
  line-height: 47px;
  padding: 6px 44px;
  transition: all 0.5s ease-in-out;
  &:hover,
  &:focus {
    background: $orange;
  }
&.stake-btn{
  font-size: 16px;
  height: 45px;
  line-height: 34px;
  margin-top: 15px;
  margin-right: 10px;
}
  @media (max-width: 991px) {
    padding: 4px 23px;
    font-size: 15px;
    height: 45px;
    line-height: 36px;
  }
  @media (max-width: 767px) {
    padding: 0px 21px;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
  }
  &.swap-btn{
    background: linear-gradient(to right, #1FC7D4, #1FC7D4);
    color: #fff;
  }
  &.ido-btn
  { 
//    background:linear-gradient(to right, #02bf02, #02bf02);
//  color: #fff;
background: transparent;
color:#ef7f1b;
border:2px solid #ef7f1b;
}
&.orbit-btn{
  background: #ef7f1b;
    color: #fff;
}
&.azbit-btn{
  background: #12172e;
  color: #fff;
}
}
