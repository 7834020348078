// @import "../../Assets/";

table {
  tbody {
    a {
      background: linear-gradient(
        90deg,
        rgba(0, 117, 221, 1) 0%,
        rgba(4, 197, 251, 1) 100%
      );
      text-transform: capitalize;
      text-decoration: none;
      padding: 5px 12px;
      color: #fff;
      border-radius: 6px;
      &:hover {
        color: #fff;
      }
    }
  }
}
#txn-model .modal-content {
  // width: auto;
}
.no_txn_found{
  color:white;
  text-align:center;
}

#txn-model th{
  padding: 8px 8px;
}


.table thead {
  border: none;
}

.table > :not(:first-child) {
  border-top: .5px solid #7d5234;
}

#txn-model .modal-content .modal-header {
  border-bottom: 1px solid #ef7b1b17 !important;
}