@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
$font: "Poppins", sans-serif !important;
$black: #000;
$white: #fff;
$orange: #ef7f1b;
$buttonbg: transparent linear-gradient(270deg, #ef5c1b 0%, #ef7f1b 100%) 0% 0%;
$darkbg: #0b1019;
//import
$baseFont: 'Poppins', sans-serif;
$baseColor: #1F91EB;
$black: #000000;
$darkBlue: #014B85;
$textColor: #556273;
$linkColor: #0060ff;
$lgtText: #8F98AA;
$buttonHover: #14af62;
$yellowColor: linear-gradient(90deg, rgba(249,191,3,1) 0%, rgba(249,216,3,1) 100%);
$bluGradient:  linear-gradient(  90deg,rgba(0, 117, 221, 1) 0%, rgba(4, 197, 251, 1) 100%);
$yellowColorHover: red;
$lightBg: #F7FAFE;
$loginBaseColor : #FFFFFF0A


