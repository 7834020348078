.modal-content {
  background-color: #343845 !important;
  border-radius: 13px;
  .modal-header {
    border-bottom: 1px solid #ef7b1b63;
    .modal-title {
      font-size: 22px;
      font-weight: 500;
    }
  }
  .modal-body {
    background-color: #343845;
    border-radius: 13px;
    .pop_body {
      display: flex;
      justify-content: space-between;
      background-color: #202231;
      padding: 20px;
      cursor: pointer;
      border-radius: 17px;
      margin: 15px 0;
      p.text-muted {
        margin: 0;
        font-size: 20px;
      }
    }
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    opacity: 9;
  }
}

.walletAddress_span {
  padding-right: 16px;
}
