@import "../../Theme/_vars.scss";
@import "../../Theme/_mixins.scss";

.headermain {
  position: absolute;
  top: 0px;
  right: 0;
  width: calc(100% - 250px);
  background: #141422 !important;
  img {
    display: none;
    @media (max-width: 991px) {
      display: flex;
      width: 35px;
      margin: 0 25px;
    }
  }
  @media (max-width: 991px) {
    position: relative;
    width: 100%;
    background: #141422 !important;
  }
  .navbar-brand {
    display: none;
    @media (max-width: 991px) {
      display: block;
      padding-left: 15px;
      img {
        width: 65px;
      }
    }
  }
  .navbar {
    .top-nav-bar {
      ul {
        display: none;
        @media (max-width: 991px) {
          display: block;
          padding: 0;
          margin: 0;
          list-style: none;
        }
        li {
          a {
            color: $baseColor;
            font-size: 13px;
            display: block;
            padding: 8px 0;
            border-bottom: 1px solid #302e2e;
            &.active {
              color: #333;
              text-decoration: none;
            }
          }
        }
      }
      .right_nav {
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
          display: block;
        }
        .nav-item {
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #fff;
          @media (max-width: 991px) {
            // color: rgba(255, 255, 255, 0.8);
            color: #fff;
          }
          .form-group {
            display: flex;
            align-items: center;
            margin: 0;
            label {
              margin: 0;
            }
            select {
              height: 35px;
              padding: 0 35px 0 10px;
              margin-left: 15px;
              background-size: 10px;
              background-position: right 7px center;
              -webkit-appearance: none;
              -moz-appearance: none;
              @media (max-width: 991px) {
                height: 30px;
                padding: 0 15px;
                margin-left: 15px;
                font-size: 11px;
                width: 250px;
                background: #1c1c1c;
                //color: rgba(255, 255, 255, 0.8);
              }
            }
          }
          @media (max-width: 1440px) {
            font-size: 14px;
          }
          @media (max-width: 991px) {
            font-size: 12px;
            font-weight: 400;
          }
        }
        .nav-link {
          padding-left: 20px;
          padding-right: 0;
          font-size: 16px;
          @media (max-width: 1280px) {
            font-size: 14px;
          }
          @media (max-width: 991px) {
            font-size: 12px;
            //color: rgba(255, 255, 255, 0.8);
            color: #fff;
          }
        }
        .nav-link.logout {
          padding-right: 14px;
          @media (max-width: 991px) {
            padding-right: 0;
            padding-left: 0;
          }
        }
        span.pipe {
          border: 1px solid $baseColor;
          height: 25px;
          margin: 0 10px;
          @media (max-width: 991px) {
            display: none;
          }
        }
      }
    }
  }
  .balance_strp {
    margin: 15px 0 0;
    @media (max-width: 991px) {
      display: inline-block;
      width: 100%;
    }
    ul {
      padding: 0 15px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      @media (max-width: 991px) {
        justify-content: space-between;
      }
      @media (max-width: 767px) {
        padding: 0 10px;
        margin-bottom: 5px;
      }

      li {
        box-shadow: 0px 3px 6px #00000008;
        padding: 7px 15px;
        background: #f8f9fa;
        border: 1px solid #e7e7e7;
        font-size: 12px;
        border-radius: 8px;
        margin-left: 15px;
        height: 58px;
        @media (max-width: 1440px) {
          font-size: 12px;
        }
        @media (max-width: 1280px) {
          font-size: 11px;
          height: 53px;
          margin-left: 10px;
          padding: 5px 10px;
        }
        @media (max-width: 1130px) {
          font-size: 10px;
          margin-left: 7px;
          padding: 5px 7px;
        }
        @media (max-width: 991px) {
          font-size: 10px;
          height: 53px;
          margin-left: 5px;
          padding: 5px 8px;
        }
        @media (max-width: 767px) {
          font-size: 9px;
          padding: 5px 9px;
          width: 48%;
          margin: 0px 0px 10px;
        }
        span {
          color: #1c1c1c;
          font-weight: 700;
          display: block;
          font-size: 15px;
          line-height: 18px;
          margin-top: 6px;
          text-align: right;
          @media (max-width: 1440px) {
            font-size: 15px;
          }
          @media (max-width: 1199px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .navbar-light {
    padding: 15px;
    @media (max-width: 991px) {
      padding: 10px 0;
      background: #1d1d2b !important;
    }
    .navbar-nav {
      padding-right: 24px;
      @media (max-width: 991px) {
        margin-top: 10px;
        background: #141422;
        padding: 10px 15px;
      }
      .nav-link {
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        padding-left: 68px;
        font-weight: 400;
        @media (max-width: 1366px) {
          font-size: 15px;
        }
        @media (max-width: 991px) {
          padding-left: 0;
        }
        &:hover {
          color: $baseColor;
        }
        &.dash {
          color: #a2a2a2;
          padding-left: 0px !important;
          position: absolute;
          left: 15px;
          &:hover {
            color: $baseColor;
          }
          @media (max-width: 991px) {
            position: relative;
            left: 0;
          }
        }
        &.mob {
          display: none;
          @media (max-width: 991px) {
            display: block !important;
          }
        }
      }
    }
  }
}
.top-nav-bar {
  width: 100%;
  justify-content: flex-end;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: unset;
  filter: invert(1);
  padding: 0;
  border: 0;
  margin-right: 15px;
  background: transparent;
  &:focus {
    box-shadow: unset !important;
    outline: unset;
  }
}

.logout {
  position: relative;
  font-size: 18px;
  text-decoration: none;
  line-height: 24px;
  color: #fff;
  padding: 0 40px;
  @media (max-width: 991px) {
    position: absolute;
    right: 75px;
    top: 15px;
    padding-left: 60px;
    padding-right: 15px;
    line-height: 24px;
    font-size: 12px;
  }
  &:after {
    content: "";
    width: 24px;
    height: 24px;
    background: url(../../Assets/images/logout_icon.png);
    position: absolute;
    left: 120px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    background-size: 22px;
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;
    @media (max-width: 991px) {
      margin: 0;
    }
  }
}
.top-nav-bar .in_reps a {
  color: #fff !important;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}
