@import "~bootstrap/scss/bootstrap";
@import "./Theme/vars.scss";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.PrivateArea__contentIn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  // min-height: 950px;
  min-height: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    display: block;
  }
}
.dashLeft {
  width: 250px;
  background: #efefef;
  height: 100%;
  @media (max-width: 991px) {
    height: auto;
    background: transparent;
    width: 100%;
  }
}
.dashRight {
  width: calc(100% - 250px);
  background: #141422;
  margin-top: 70px;
  padding: 20px 12px 65px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #000;
  @media (max-width: 1440px) {
    margin-top: 140px;
  }
  @media (max-width: 992px) {
    margin: 0;
    width: 100%;
  }
  .copyright {
    padding: 15px 0;
    position: absolute;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 9;
    bottom: 0;
    p {
      // color: #000 !important;
      padding: 0;
      @media (max-width: 991px) {
        font-size: 13px;
      }
    }
  }
  .requestTable .badge {
    color: $white;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 10px;
    border-radius: 6px;
  }
  .requestTable .cus-btn {
    line-height: 20px;
    height: auto;
    width: auto;
    padding: 8px 12px;
    font-size: 13px;
    margin: 0 3px;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #1d1d2b;
  color: #fff;
  border-radius: 10px;
  white-space: nowrap;
  thead {
    border: 1px solid slategray;
    th {
      font-size: 16px;
      font-weight: 400;
      border-bottom: 0;
      border-top: 0;
      color: #ef601b;
    }
  }
  tbody {
    tr {
      // border: 1px solid slategrey;
    }
    td {
      border: none;
      vertical-align: middle;
      @media (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
}
.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.075);
}
::-webkit-scrollbar {
  height: 10px;
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: #fff;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #353a3e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background-color: #060608 !important;
}

.create-request {
  float: right;
  padding: 15px;
  .c_reqst {
    height: 54px;
    line-height: 43px;
    @media (max-width: 767px) {
      height: 43px;
      line-height: 43px;
    }
  }
}
#react-confirm-alert .react-confirm-alert-overlay {
  z-index: 9999;
}

.modal .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #fff;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 1;
    filter: invert(1);
}

.no-records{
  margin-top: 150px;
  font-size: 25px;
}